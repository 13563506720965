import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useLayoutContext } from "../../../providers/layout.provider";
import { IUser } from "../../../types/types";
import "./leftpane.component.css";

export default function LeftPaneItems({
  onPostClick,
  anchorEl,
  open,
  user,
}: {
  open: boolean;
  anchorEl: HTMLElement | null;
  onPostClick: (index: number) => void;
  user?: IUser | null;
}) {
  const { isMobile } = useLayoutContext();

  return (
    <Menu
      id="basic-menu"
      open={open}
      anchorEl={anchorEl}
      onClose={onPostClick}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {!user && (
        <MenuItem onClick={() => onPostClick(0)}>What's ExpenseHut</MenuItem>
      )}
      {!!user && (
        <>
          <MenuItem onClick={() => onPostClick(1)}>Profile</MenuItem>
          <hr />
          <MenuItem onClick={() => onPostClick(2)}>Change Password</MenuItem>
          <hr />
          <MenuItem onClick={() => onPostClick(3)}>Logout</MenuItem>
        </>
      )}
    </Menu>
  );
}
