import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import LogoComponent from "../components/leftpane/items/logo.component";
import { useAuthContext } from "../providers/auth.provider";
import LeftPaneItems from "./leftpane/items/leftpane.items";
import { usePostLogout } from "../api/api";
import { getAppURL } from "../utils/app";

export default function MobileHeader() {
  const { user, signout } = useAuthContext();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const navigate = useNavigate();

  const handleClick = (index: number) => {
    setAnchorEl(null);
    if (index === 0) {
      window.location.href = "https://www.expensehut.com";
    } else if (index === 1) {
      navigate("/settings/profile");
    } else if (index === 2) {
      navigate("/settings/change-password");
    } else if (index === 3) {
      signout();
    }
  };

  return (
    <div className="" style={{ backgroundColor: "var(--exhut-primary)" }}>
      <div className="divSpread divAlignItemsOnly body">
        <LogoComponent isAuthenticated={!!user} />
        <OptionsMenu
          id="toggle-options"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        />
      </div>
      <LeftPaneItems
        anchorEl={anchorEl}
        open={!!anchorEl}
        onPostClick={handleClick}
        user={user}
      />
    </div>
  );
}

export function OptionsMenu(props: React.HTMLProps<{}>) {
  return (
    <div style={{ height: 40, marginBottom: 40 }}>
      <IconButton
        color="default"
        aria-label="open drawer"
        onClick={props.onClick}
        edge="start"
      >
        <MenuIcon htmlColor="white" />
      </IconButton>
    </div>
  );
}
