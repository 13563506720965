import React from "react";
import { Link } from "react-router-dom";

interface IProps {
  isAuthenticated: boolean;
}
export default class LogoComponent extends React.Component<IProps> {
  render() {
    return (
      <Link
        to={this.props.isAuthenticated ? "/home" : "/"}
        style={{ textDecoration: "none" }}
      >
        <div
          id="logo-container"
          style={{
            padding: 30,
            cursor: "pointer",
            backgroundColor: "var(--exhut-primary)",
          }}
        >
          <div
            style={{
              border: "3px solid white",
              borderRadius: 10,
              padding: 10,
              textAlign: "center",
            }}
          >
            <span
              style={{ color: "white", fontSize: 40, textDecoration: "none" }}
            >
              Hub
            </span>
          </div>
          <div style={{ textAlign: "end", padding: 5 }}>
            <span style={{ color: "white", textDecoration: "none" }}>by </span>
            <span style={{ color: "white", fontWeight: "bold" }}>expense</span>
            <span style={{ color: "yellow", fontWeight: "bold" }}>hut</span>
          </div>
        </div>
      </Link>
    );
  }
}
