import { Link } from "react-router-dom";
import { useAuthContext } from "../../providers/auth.provider";

export default function SettingsPage() {
  const { user } = useAuthContext();
  return (
    <div className="body">
      <div className="title">Settings </div>

      <div className="divCenterAlign" style={{ textAlign: "left" }}>
        <table className="exhut-table">
          <tbody>
            <tr>
              <td>Update personal details & account deletion</td>
              <td>
                <Link to="/settings/profile">Profile</Link>
              </td>
            </tr>
            <tr>
              <td>Change Password</td>
              <td>
                <Link to="/settings/change-password">Change Password</Link>
              </td>
            </tr>
            {user?.admin === true && (
              <tr>
                <td>Admin</td>
                <td>
                  <Link to="/admin">Admin</Link>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
