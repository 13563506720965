import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useFaqHighlight() {
  const location = useLocation();

  useEffect(() => {
    const id = location.hash;
    const existing = document.querySelectorAll(".faq-highlight");
    existing.forEach((e) => e.classList.remove("faq-highlight"));
    if (id) {
      const element = document.querySelector(`${id}`);
      element?.classList.add("faq-highlight");
    }
  }, [location.hash]);

  return null;
}
