import {
  FAQAccessExpenseHutStopRecruit,
  FAQAccessRecruitWithExpenseHut,
  FAQDeleteAccount,
  FAQGhostJob,
  FAQJobCaveat,
  FAQJobPostReview,
  FAQJobScrapingSupport,
  FAQRecruitBadge,
  FAQStaleJob,
  FAQWhatIsExpenseHut,
  FAQWhatIsRecruit,
  FAQWhyRecruit,
} from "../../components/faq.individual.component";
import useFaqHighlight from "../../hooks/useFaqHighlight";

export default function FAQPublicPage() {
  useFaqHighlight();

  return (
    <div className="splitright">
      <div>
        <div className="title">FAQ</div>

        <div className="divFlex">
          <div className="p-40">
            <FAQWhatIsRecruit />

            <FAQWhyRecruit />

            <FAQJobPostReview />

            <FAQGhostJob />

            <FAQStaleJob />

            <FAQRecruitBadge />

            <FAQJobCaveat />

            <FAQJobScrapingSupport />

            <FAQWhatIsExpenseHut />

            <FAQAccessRecruitWithExpenseHut />

            <FAQAccessExpenseHutStopRecruit />

            <FAQDeleteAccount />
          </div>
        </div>
      </div>
    </div>
  );
}
