export function FAQWhatIsRecruit() {
  return (
    <div className="faq-formrow">
      <strong style={{ flex: 1 }}>What is Recruit?</strong>

      <div style={{ flex: 3 }}>
        Recruit is a next gen job portal developed to help job searchers to land
        in an interview using a special mechanism called{" "}
        <a href="#job-post-review">Job Post Reviews</a>.
      </div>
    </div>
  );
}

export function FAQWhyRecruit() {
  return (
    <div className="faq-formrow">
      <strong style={{ flex: 1 }}>Why Recruit?</strong>

      <div style={{ flex: 3 }}>
        Job portals today are unable to eliminate{" "}
        <a href="#ghost-jobs">ghost</a> or <a href="#stale-jobs">stale</a> jobs.
        This results in more time wasted by candidates filling out job
        applications but they never hear back. Recruit was developed to solve
        this particular problem.
      </div>
    </div>
  );
}

export function FAQGhostJob() {
  return (
    <div className="faq-formrow" id="ghost-jobs">
      <strong style={{ flex: 1 }}>What are Ghost Jobs?</strong>

      <div style={{ flex: 3 }}>
        Ghost jobs are job postings in the web that simply pretend to exist.
        Behind the scenes they are likely added by employers to learn current
        demand for a particular skill set or to falsely advertise growth.
      </div>
    </div>
  );
}

export function FAQStaleJob() {
  return (
    <div className="faq-formrow" id="stale-jobs">
      <strong style={{ flex: 1 }}>What are Stale Jobs?</strong>

      <div style={{ flex: 3 }}>
        Stale Jobs are jobs present in the job portals that have already been
        expired. This usually happens if the job portal scrapes from the
        company's database very late or fails to deactivate after it's lifetime.
      </div>
    </div>
  );
}

export function FAQJobPostReview() {
  return (
    <div className="faq-formrow" id="job-post-review">
      <strong style={{ flex: 1 }}>
        <p>Job Post Review</p>
      </strong>

      <div style={{ flex: 3 }}>
        <p>
          Recruit features a mechanism called <strong>Job Post Reviews</strong>{" "}
          to increase credibility for each job posted in the internet.
        </p>

        <p>
          A rating score is calculated for each job based on a set of parameters
          including, but not limited to
          <ol style={{ listStyle: "numbered" }}>
            <li>Average responses by a company.</li>
            <li>Work visa sponsorship availability.</li>
            <li>Most popular skillset</li>
            <li>Years of experience.</li>
          </ol>
        </p>

        <p>
          Job searchers uses this rating score in order to determine if they are
          a <strong>right fit</strong>for this role.
        </p>

        <p>
          On an average a candidate spends atleast 2 mins to fill out a job
          application and applies atleast 20 jobs to land in an interview.
        </p>

        <strong>
          Recruit can help you save time in finding the right job.
        </strong>
      </div>
    </div>
  );
}

export function FAQRecruitBadge() {
  return (
    <div className="faq-formrow" id="recruit-badge">
      <strong style={{ flex: 1 }}>What is Recruit Badge?</strong>

      <div style={{ flex: 3 }} className="divTextAlignOnly">
        Also known as Job Post Review score, we calculate a score out of 5 that
        indicates if candidates are receiving a response from companies.
      </div>
    </div>
  );
}

export function FAQJobScrapingSupport() {
  return (
    <div className="faq-formrow">
      <strong style={{ flex: 1 }}>Do you support job scraping?</strong>

      <div style={{ flex: 3 }}>
        Not yet. But if your company has its own mechanism to publish jobs we
        can help you identify a solution.
      </div>
    </div>
  );
}

export function FAQJobCaveat() {
  return (
    <div className="faq-formrow" id="caveat">
      <strong style={{ flex: 1 }}>The caveat</strong>

      <div style={{ flex: 3 }}>
        Companies can also be actively responding to candidates by rejecting via
        canned email responses. We address this problem by allowing candidates
        to indicate if they were shortlisted for an interview. Unless the short
        listed candidates use Recruit, we may see discrepancies in the data.
      </div>
    </div>
  );
}

export function FAQWhatIsExpenseHut() {
  return (
    <div className="faq-formrow">
      <strong style={{ flex: 1 }}>What is ExpenseHut?</strong>

      <div style={{ flex: 3 }}>
        <a href="https://www.expensehut.com" target="_blank" rel="noreferrer">
          ExpenseHut
        </a>{" "}
        originally started as a research product, is now a free finance
        management platform for personal finance. It is currently available in
        web, Android and iOS.
      </div>
    </div>
  );
}

export function FAQAccessRecruitWithExpenseHut() {
  return (
    <div className="faq-formrow">
      <strong style={{ flex: 1 }}>
        Being an ExpenseHut user, should I register <br /> again to use Recruit?
      </strong>

      <div style={{ flex: 3 }}>
        No. As an ExpenseHut user, you automatically gain access to Recruit.
      </div>
    </div>
  );
}

export function FAQAccessExpenseHutStopRecruit() {
  return (
    <div className="faq-formrow">
      <strong style={{ flex: 1 }}>
        I want to stop using Recruit, but continue <br /> using ExpenseHut.
      </strong>

      <div style={{ flex: 3 }}>
        Login to Recruit and delete your profile. This will <strong>not</strong>{" "}
        delete your jobs or job reviews but you will permanently lose ownership
        to them. Deleting Recruit profile will have no impact on your data in{" "}
        <a href="https://expensehut.com" target="_blank" rel="noreferrer">
          ExpenseHut
        </a>
        ,{" "}
        <a
          href="https://wallet.expensehut.com"
          target="_blank"
          rel="noreferrer"
        >
          Wallet
        </a>{" "}
        or{" "}
        <a href="https://blogs.expensehut.com" target="_blank" rel="noreferrer">
          Blogs
        </a>
        . You can come back and re-create your Recruit profile anytime.
      </div>
    </div>
  );
}

export function FAQDeleteAccount() {
  return (
    <div className="faq-formrow">
      <strong style={{ flex: 1 }}>
        I want to remove myself from the entire ExpenseHut platform.
      </strong>

      <div style={{ flex: 3 }}>
        Please follow{" "}
        <a
          href="https://help.expensehut.com/deactivateAccount"
          target="_blank"
          rel="noreferrer"
        >
          this
        </a>{" "}
        article.
      </div>
    </div>
  );
}
