import moment from "moment";

export default function FooterComponent() {
  return (
    <div className="footer p-16">
      <div className="footer-copyright">
        {" "}
        &copy; {moment().format("YYYY")} www.expensehut.com{" "}
      </div>
      <div className="mt-10">
        {" "}
        A{" "}
        <a
          target="_blank"
          rel="noreferrer"
          style={{ color: "yellow" }}
          href="https://www.sabarishnarain.com"
        >
          SabarishNarain{" "}
        </a>{" "}
        Project{" "}
      </div>
    </div>
  );
}
